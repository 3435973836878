import { graphql } from 'gatsby';
import React from 'react';
import { InfiniteScroll } from 'react-simple-infinite-scroll-patched';
import { ActivityIndicator } from '../../components/atoms/activityIndicator';
import { PostQuery, PostSeriesQuery } from '../../graphql-generated';
import { PageProps } from '../../types/PageProps';
import { PostSeriesContext } from '../../types/PostSeriesContext';
import { parseLink } from '../../utils/parseLink';
import Post from '../post/index';
import './postSeries.scss';

const PostSeries: React.FC<PageProps<PostSeriesQuery, PostSeriesContext>> = ({
  data,
  path,
  pageContext,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [relatedPosts, setRelatedPosts] = React.useState<Array<any>>([]);
  const hasMore = relatedPosts.length < (pageContext?.relatedPostsLength ?? 0);

  const onLoadMore = async () => {
    if (!hasMore || isLoading) return;
    setIsLoading(true);

    const result = await (
      await fetch(
        `${pageContext?.relativeRelatedPostsBaseDir}/${
          relatedPosts.length + 1
        }.json`
      )
    ).json();
    setRelatedPosts((previousRelatedPosts) => [
      ...previousRelatedPosts,
      result,
    ]);
    setIsLoading(false);
  };

  return (
    <>
      <Post data={data} path={path} pageContext={pageContext} />

      <InfiniteScroll
        throttle={100}
        hasMore={hasMore}
        isLoading={isLoading}
        onLoadMore={onLoadMore}
      >
        <ActivityIndicator
          active={isLoading}
          inline
          className="post-series-activity-indicator"
        />

        {relatedPosts.map((post: any, i: number) => {
          const link = parseLink(post.link).slice(1);
          return (
            <Post
              key={post?.title ?? i}
              data={
                {
                  post,
                } as PostQuery
              }
              path={link}
              renderLatestNews={false}
              renderSeo={false}
            />
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export const query = graphql`
  query PostSeriesQuery($id: String!) {
    post: wpPost(id: { eq: $id }) {
      ...Post
    }
    wp {
      options {
        acfPostFooterLayout {
          postFooterBottomText
          postFooterText
        }
      }
    }
  }
`;

export default PostSeries;
